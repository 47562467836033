
























import { defineComponent, useRouter, ref, watch } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'FinancingSection',
  setup() {
    const { user, isAuthenticated } = useUser();
    const { toggleLoginModal } = useUiState();
    const router = useRouter();
    const creditBtnClicked = ref(false)
    const goToFinanceForm = () => {
      if(isAuthenticated.value) {
        router.push({ path: '/credit-application' });
      }
      else {
        creditBtnClicked.value = true
        toggleLoginModal()
      }
    }
    watch(() => user.value, async () => {
      if (creditBtnClicked.value) {
        setTimeout(() => {
          router.push({ path: '/credit-application' });
        },3000)
      }
    });
    return {
      goToFinanceForm
    }
  }
});
